.altraser-logo {
  animation: altraser-logo-spin infinite 20s linear;
  height: 80px;
}

.todoapp {
}
.nav-tabs.formtab {
  border-bottom: none;
}
.nav-tabs.formtab .nav-link.active {
  background-color: #fff;
}

.form-group.has-error small.form-text.text-muted {
  color: $danger-color !important;
}

.album div.card-header {
  text-transform: uppercase;
  // font-family: 'Varela', sans-serif;
  font-weight: bold;
}

.smaller {
  font-size: 0.76rem;
}

.todo-item-label {
  display: block;
  margin-left: 16px;
}

.navbar-brand {
  font-size: 1.62rem;
  letter-spacing: 0.1rem;
  color: #cc0000;
}

.brand-margin {
  padding-right: 42px;
}

.icon-logo {
  margin-right: 6px;
}

@keyframes altraser-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  --jumbotron-padding-y: 3rem;
}

body {
  line-height: 1.72rem;
  background-color: #fff;
}

.jumbotron {
  padding-top: var(--jumbotron-padding-y);
  padding-bottom: var(--jumbotron-padding-y);
  margin-bottom: 0;
  background-color: #fff;
  p:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .jumbotron {
    padding-top: calc(var(--jumbotron-padding-y) * 2);
    padding-bottom: calc(var(--jumbotron-padding-y) * 2);
  }
}

.jumbotron-heading {
  font-weight: 300;
}

.jumbotron .container {
  max-width: 40rem;
}

.section {
  padding-top: 3.2rem;
  padding-bottom: 4.2rem;
}

footer {
    background-color: #fff;
  padding-top: 3rem;
  padding-bottom: 3rem;
  p {
    margin-bottom: 0.25rem;
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.pulse {
  animation-name: pulse_animation;
  animation-duration: 4000ms;
  transform-origin: 50% 100%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes pulse_animation {
  0% {
    transform: scale(1);
  }
  35% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.2);
  }
  45% {
    transform: scale(1);
  }
  55% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.3);
  }
  65% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
