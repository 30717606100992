// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';
@import 'colors';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import 'acemen';

body {
    background-color: #fff;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.card-body-icon{
    position:absolute;
    z-index:0;
    top:-25px;
    right:-25px;
    font-size:5rem;
    -webkit-transform:rotate(15deg);
    transform:rotate(15deg)
}

.o-hidden {
  overflow: hidden !important;
}

.pulse {
  animation-name: pulse_animation;
  animation-duration: 4000ms;
  transform-origin: 50% 100%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes pulse_animation {
  0% {
    transform: scale(1);
  }
  35% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.2);
  }
  45% {
    transform: scale(1);
  }
  55% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.3);
  }
  65% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
